import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AssetData } from 'src/app/models/assetData.model';
import { SelectItem } from 'src/app/models/mapFilter.model';
import { DashboardService } from 'src/app/services/dashboard.service';
import { MapComponent } from '../../map/map.component';
import { Coordinates } from 'src/app/models/coordinates.model';

@Component({
  selector: 'sales-management',
  templateUrl: './sales-management.component.html',
  styleUrls: ['./sales-management.component.scss'],
})
export class SalesManagementComponent implements OnInit {
  @ViewChild('childRef', { static: false }) childComponent: MapComponent;

  customersOpen: boolean = false;
  buildingsOpen: boolean = false;
  polesOpen: boolean = false;
  transformersOpen: boolean = false;
  initialLoad: boolean = true;
  @Input() increasement: number = 0;
  @Input() polesData: AssetData = new AssetData();
  @Input() customersData: AssetData = new AssetData();
  @Input() buildingsData: AssetData = new AssetData();
  @Input() transformersData: AssetData = new AssetData();
  @Input() mapData: SelectItem[] = [];
  @Input() areaIds: number[] = [];
  @Input() utilityId: number;
  @Input() reloadMapTrigger: boolean = true;
  isMapExpanded: boolean = false;

  defaultCoordinates: Coordinates;
  constructor(
    private dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    // this.getDefaultCoordinates();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['utilityId'] && changes['utilityId'].previousValue != changes['utilityId'].currentValue)
      this.getDefaultCoordinates(changes['utilityId'].currentValue.length == 1 ? this.utilityId : undefined);
    else if (this.initialLoad) {
      this.defaultCoordinates = this.defaultCoordinates ?? this.getDefaultCoordinates();
    }

    this.initialLoad = false;

  }

  onMapExpandedChanged(isMapExpanded: boolean) {
    this.isMapExpanded = isMapExpanded;
  }

  getDefaultCoordinates(utilityId?: number) {
    if (this.utilityId) {
      this.defaultCoordinates = new Coordinates(5.6026283219970585, -0.18522295756972676);
    } else {
      this.dashboardService.getDefaultCoordinates(utilityId ?? 0).subscribe({
        next: (data) => {
          this.defaultCoordinates = new Coordinates(data.body?.latitude ?? 0, data.body?.longitude ?? 0);
        },
        error: (error) => console.log(error.statusText),
      });
    }
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-utility-dropdown-single',
  templateUrl: './utility-dropdown-single.component.html',
  styleUrls: ['./utility-dropdown-single.component.scss']
})
export class UtilityDropdownSingleComponent implements OnInit {

 
   utilityList: any[];
   selectedUtility: number ;
   selectedUtilityId: number = 0;
 
   @Output() selectUtility = new EventEmitter();
 
   constructor(private utilityService: UtilityService) { }
 
   ngOnInit(): void {
     this.getUtilities();
   }
 
   getUtilities(){
     this.utilityService.getUtilitiesForSelect().subscribe({
       next: (data) => {
         this.utilityList = data.data;
         this.selectedUtility = this.utilityList.map(s=>s.key)[0];
         this.selectUtility.emit(this.selectedUtility)
       },
       error: (error) => console.log(error.statusText)
     }); 
   }
 
   selectUtilityChild(e: any) {
    
       this.selectedUtilityId = parseInt(e.target.value);
       this.selectedUtility = this.selectedUtilityId
     
     
     this.selectUtility.emit(this.selectedUtility)
   }
}

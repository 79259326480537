export class KeyValueItem {
    key: string = "";
    value: number = 0;
    isClickable = true;
    items: KeyValueItem[] = [];
    order: number;
    friendlyName?: string;
}

export class KeyValuePair {
    key: string = "";
    value: string = "";
}

export class KeyValueIntString {
    key: number;
    value: string = "";
}
import { Injectable } from '@angular/core';
import { PaginationDto, ResponsePackage } from '../models/util.model';
import { SurveyDetails } from 'src/app/models/survey.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../config/config';
import { KeyValueItem } from '../models/keyValueItem.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  private pathApi = this.config.setting['pathApi'] + "survey/";
  private headers = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(private http: HttpClient, private config: AppConfig) {}

  getAll(obj: any) {
    return this.http.post<ResponsePackage<PaginationDto<SurveyDetails>>>(`${this.pathApi}getAll`, obj, {headers: this.headers, observe: 'response'});
  }
  save(obj: any) {
    return this.http.post<ResponsePackage<string>>(`${this.pathApi}save`, obj, {headers: this.headers, observe: 'response'});
  }
  getById(id:number){
    return this.http.get<ResponsePackage<any>>(`${this.pathApi}${id}`,{headers: this.headers, observe: 'response'});
  }
  getDetailsById(id:number){
    return this.http.get<ResponsePackage<any>>(`${this.pathApi}details/${id}`,{headers: this.headers, observe: 'response'});
  }
  download(obj:any){
    return this.http.post<ResponsePackage<string>>(`${this.pathApi}downloadFile`, obj, {headers: this.headers, observe: 'response'});
  }

  getSurveyStats() {
    return this.http.get<ResponsePackage<KeyValueItem[]>>(`${this.pathApi}getSurveyStats`,{headers: this.headers, observe: 'response'});
  }
  uploadFile(obj: any, utilityId: number): Observable<any> {
    return this.http.post(`${this.pathApi}uploadFile?utilityId=${utilityId}`, obj);
  } 
  generateTemplate(utilityId: number): Observable<any> {
    return this.http.get(`${this.pathApi}generateTemplate?utilityId=${utilityId}`);
  }
  approveSurvey(obj:any):Observable<any>{
    return this.http.post(`${this.pathApi}approveSurvey`, obj);
  }
  rejectSurvey(obj:any):Observable<any>{
    return this.http.post(`${this.pathApi}rejectSurvey`, obj);
  }
  validateSurvey(obj: any):Observable<any>{
    return this.http.post(`${this.pathApi}validateSurvey`, obj);
  }
  recallSurvey(id:number):Observable<any>{
    return this.http.post<ResponsePackage<string>>(`${this.pathApi}recallSurvey`, id, {headers: this.headers, observe: 'response'});
  }
}

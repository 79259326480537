import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InstallationDetails } from 'src/app/models/installation.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { InstallationService } from 'src/app/services/installation.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { TranslationService } from 'src/app/services/translation.service';
import { InstallationAndPowerSource } from 'src/app/models/customer.model';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-installation-details',
  templateUrl: './installation-details.component.html',
  styleUrls: ['./installation-details.component.scss'],
})
export class InstallationDetailsComponent implements OnInit {
  public PermissionsEnum = PermissionsEnum;
  public InstallationAndPowerSource = InstallationAndPowerSource;

  id: string = '';
  rejectionNote : string='';
  selectedImage: any;
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  contractNumber: string = this.translationService.getByKeyFromStorage('Contract number');
  imgElement: any;
  imgZoomFullContainer: any;
  currentRotation: number = 0;

  selectedInstallation :InstallationDetails = new InstallationDetails();
  isGhanaPostFieldsVisible: boolean = true;
  isSPNFieldVisible: boolean = true;
  isGeoCodeFieldVisible: boolean = true;
  isActivityCodeFieldVisible: boolean = true;
  isCurrentPlotCodeFieldVisible: boolean = true;
  isMapVisible: boolean = false;
  filterText = '';
  tempAssignedUserId: number = 0;
  mobileUsers: User[] = [];
  isDropdownOpened: boolean = false;
  filteredMobileUsers: User[] = [];
  rejectToOption: string;

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private installationService: InstallationService,
    public permissionsService: PermissionsService,
    private translationService: TranslationService,
    private userService: UserService,
    private el: ElementRef, 
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.setFieldsVisibility();
    this.id = this.route.snapshot.paramMap.get('id') || '';
    this.imgElement = this.el?.nativeElement?.querySelector('.ngxImageZoomThumbnail');
    this.imgZoomFullContainer = this.el?.nativeElement?.querySelector('.ngxImageZoomFull');

    this.getSelectedInstallation(parseInt(this.id));
  }

  onOptionChange(event: Event) {
    const target = event.target as HTMLSelectElement;
    this.rejectToOption = target.value;
    this.tempAssignedUserId = 0;
    this.filterText = '';
  }

  filterMobileUsers() {
    if (this.filterText.length < 3) {
      return;
    }
    this.isDropdownOpened = true;
    this.tempAssignedUserId = 0;
    this.getMobileUsers();
  }

  getMobileUsers() {
    this.userService.getMobileUsers(this.selectedInstallation.customer.areaId, this.filterText).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.mobileUsers = responseData.data;
          if (this.mobileUsers.length == 0) {
            this.isDropdownOpened = false;
          }
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  selectFieldAgent(id: number) {
    this.isDropdownOpened = false;
    this.tempAssignedUserId = id;
    var mobUser = this.mobileUsers.find((x) => x.id == id);
    if (mobUser?.firstName && mobUser?.lastName) {
      this.filterText = mobUser!.firstName + ' ' + mobUser?.lastName;
    } else {
      this.filterText = mobUser!.firstName;
    }
  }

  approve(){
    this.installationService.approveInstallation(parseInt(this.id)).subscribe(
      (data) => {
        this.toastr.success('Installation Approved');
        window.location.reload();
    },
    (error) => {
      this.toastr.error('An error ocurred.');
    }
  );
  }
  reject(){
    let obj={
      id:parseInt(this.id),
      note:this.rejectionNote,
      assignToId: this.tempAssignedUserId,
    }
    if(this.rejectToOption === 'fieldAgent' && this.tempAssignedUserId === 0) 
      this.toastr.error('Select field Agent');
    else
      this.installationService.rejectInstallation(obj).subscribe(
        (data) => {
          this.toastr.success('Installation Rejected');
          window.location.reload();
      },
      (error) => {
        this.toastr.error('An error ocurred.');
      }
  );
  }

  recallInstallation(id: number){
    this.installationService.recallInstallation(id).subscribe(
      (data) => {
        var response = data.body;
        if(response.status === 'OK'){
          this.toastr.success("Installation recalled sucessfully.")
          window.location.reload();
        }else{
          this.toastr.error("Installation is not recalled, something went wrong.")
        }
    })
  }

  getSelectedInstallation(id: number) {
    this.installationService.getDetailsById(id).subscribe(
      (data) => {
        var response = data.body;
        if(response?.status==='OK') {
            this.selectedInstallation = new InstallationDetails(data?.body?.data);
        }

        if(response?.status==='BadRequest'){
          this.toastr.error(response.message);
        }
      },
      (error) => {
        this.toastr.error('An error ocurred.');
      }
    );
  }
  showImage(num: number) {
    if (num === 1) {
     this.selectedImage = this.getCustomersSignature();
    } else if (num == 2) {
      this.selectedImage = this.getFieldRepSignature();
    }else if (num == 3) {
      this.selectedImage = this.getFrontInstallationImage();
    }else if (num == 4) {
      this.selectedImage = this.getCustomerBillImage();
    }else if (num == 5) {
      this.selectedImage = this.getProposedMeterPointBeforeInstallationImage();
    }else if (num == 6) {
      this.selectedImage = this.getInstallationInProgressImage();
    }else if (num == 7) {
      this.selectedImage = this.getSealImage();
    }else if (num == 8) {
      this.selectedImage = this.getInstallationWithSealImage();
    }else if (num == 9) {
      this.selectedImage = this.getMeterCardImage();
    }else if (num == 10) {
      this.selectedImage = this.getFullViewOfSupplyImage();
    }else if (num == 11) {
      this.selectedImage = this.getSupplyCableImage();
    }else if (num == 12) {
      this.selectedImage = this.getRetrievedMeterImage();
    }else if (num == 13) {
      this.selectedImage = this.getMonitorUIUImage();
    }else if (num == 14) {
      this.selectedImage = this.getOldMeterBoxSealImage();
    }else if (num == 15) {
      this.selectedImage = this.getOldMeterSealImage();
    }else if (num == 16) {
      this.selectedImage = this.getOldTTBSealImage();
    }else if (num == 17) {
      this.selectedImage = this.getOldCTSealImage();
    }else if (num == 18) {
      this.selectedImage = this.getMeterBoxSealImage();
    }else if (num == 19) {
      this.selectedImage = this.getMeterSealImage();
    }else if (num == 20) {
      this.selectedImage = this.getTTBSealImage();
    }else if (num == 21) {
      this.selectedImage = this.getCTSealImage();
    }
}



getCustomersSignature() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'CustomerSignature')?.url;
}
getFieldRepSignature() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'FieldRepSignature')?.url;
 
}

getFrontInstallationImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'FrontInstallationImage')?.url;
}
getCustomerBillImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'CustomerBillImage')?.url;
}
getProposedMeterPointBeforeInstallationImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'ProposedMeterPointBeforeInstallationImage')?.url;
}
getInstallationInProgressImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'InstallationInProgressImage')?.url;
}
getSealImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'SealImage')?.url;
}
getInstallationWithSealImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'InstallationWithSealImage')?.url;
}
getMeterCardImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'MeterCardImage')?.url;
}
getFullViewOfSupplyImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'FullViewOfSupplyImage')?.url;
}
getSupplyCableImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'SupplyCableImage')?.url;
}
getRetrievedMeterImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'RetrievedMeterImage')?.url;
}
getMonitorUIUImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'MonitorUIUImage')?.url;
}

getOldMeterBoxSealImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'OldMeterBoxSeal')?.url;
}
getOldMeterSealImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'OldMeterSeal')?.url;
}
getOldTTBSealImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'OldTTBSeal')?.url;
}
getOldCTSealImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'OldCTSeal')?.url;
}
getMeterBoxSealImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'MeterBoxSeal')?.url;
}
getMeterSealImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'MeterSeal')?.url;
}
getTTBSealImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'TTBSeal')?.url;
}
getCTSealImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'CTSeal')?.url;
}
onModalFade() {
  this.currentRotation = 0;

  if(this.imgZoomFullContainer) {
    var displayValue = getComputedStyle(this.imgZoomFullContainer).display;

    if(displayValue == 'block') {
      this.imgElement.click();
    }
  }
}

rotateLeft() {
  this.currentRotation -= 90;
}

rotateRight() {
  this.currentRotation += 90;
}

onModalOpen() {
  this.renderer.setStyle(document.body, 'overflow', 'auto');
  this.renderer.setStyle(document.body, 'overflow-x', 'hidden');
  this.renderer.setStyle(document.body, 'padding-right', '0');
}

setFieldsVisibility() {
    let notVisibleFields: any = localStorage.getItem('notVisibleFields');

    if (!notVisibleFields) {
        return;
    }

    notVisibleFields = JSON.parse(notVisibleFields);
    this.isGhanaPostFieldsVisible = notVisibleFields.find((el: any) => el === 'ghanaPostCode') ? false : true;

    this.isSPNFieldVisible = notVisibleFields.find(
      (el: any) => el === 'spn'
    )
      ? false
      : true;

    this.isGeoCodeFieldVisible = notVisibleFields.find(
      (el: any) => el === 'geoCode'
    )
      ? false
      : true;

    this.isActivityCodeFieldVisible = notVisibleFields.find(
      (el: any) => el === 'activityCode'
    )
      ? false
      : true;

    this.isCurrentPlotCodeFieldVisible = notVisibleFields.find(
      (el: any) => el === 'currentPlotCode'
    )
      ? false
      : true;
}
}

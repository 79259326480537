<div *ngIf="!showApprove && !showReject" class="modal-footer updateStatus">
  <button
    type="button"
    class="btn btn-primary approve"
    (click)="approve($event)"
  >
    {{'COMMON.APPROVE' | translate}}
  </button>
  <button type="button" class="btn btn-danger" (click)="reject($event)">
    {{'COMMON.REJECT' | translate}}
  </button>
</div>
<div *ngIf="showApprove" class="modal-footer updateStatus">
  <div>
    <h3>{{'DATA_QUALITY.APPROVE' | translate}}</h3>
  </div>

  <button
    type="button"
    class="btn btn-primary approve"
    (click)="submitApproval()"
  >
    {{'COMMON.YES' | translate}}
  </button>
  <button type="button" (click)="cancel($event)" class="btn btn-danger">
    {{'COMMON.NO' | translate}}
  </button>
</div>
<div *ngIf="showReject">
  <form class="form-wrapper">
    <div *ngFor="let question of dataQuality.questions">
      <div
        *ngIf="question.fieldType.toString() == 'Select'"
        style="margin-bottom: 2rem"
      >
        <div class="form-item form-floating mb-3">
          <select
            class="form-select"
            [ngClass]="checkSelect(question.options) ? 'selected-option' : ''"
            (change)="onOptionSelected(question, $event)"
          >
            <option disabled [selected]="question.label != 'Assign to User'">
              {{ question.label }}
            </option>
            <option
              [selected]="
                question.label == 'Assign to User' &&
                option.text == 'Assign to Rejection Report page'
              "
              name="answer"
              ngDefaultControl
              *ngFor="let option of question.options"
              [value]="option.text"
            >
              {{ option.text }}
            </option>
          </select>
          <label *ngIf="checkSelect(question.options)">{{
            question.label
          }}</label>
        </div>
        <div
          *ngIf="
            assignToFieldSupervisorSelected &&
            question.label == 'Assign to User'
          "
        >
          <div class="form-item form-floating mb-3">
            <div class="form-floating form-item">
              <input
                type="text"
                name="filterText"
                [(ngModel)]="filterText"
                class="form-control filter-field"
                [ngClass]="{ 'valid-input': tempAssignedUserId != 0 }"
                id="floatingInput"
                (ngModelChange)="filterMobileUsers()"
                placeholder=" "
              />
              <label for="floatingInput">{{'COMMON.FIELD_AGENT' | translate}}</label>

              <div
                class="filtered-agent-list"
                *ngIf="filterText && filterText.length > 2 && isDropdownOpened"
                #dropdownContainer
              >
                <div
                  click-stop-propagation
                  class="agent-name"
                  (click)="selectFieldAgent(u.id)"
                  *ngFor="let u of mobileUsers"
                >
                  {{ u.firstName }} {{ u.lastName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="question.fieldType.toString() == 'Checkbox'"
        class="checkbox-section"
        style="align-items: start"
      >
        <div class="question-label">{{ question.label }}</div>
        <label *ngFor="let option of question.options">
          <input
            type="checkbox"
            [(ngModel)]="option.isSelected"
            [ngModelOptions]="{ standalone: true }"
            (change)="fieldsChange(option, question.options)"
            value="option"
          />
          {{ option.text }}
        </label>
        <br />
      </div>
      <div
        *ngIf="question.fieldType.toString() == 'Text'"
        class="form-item form-floating mb-3"
        style="padding-bottom: 20px;"
      >
        <input
          id="questionVal"
          class="form-control"
          type="text"
          placeholder=" "
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="question.inputValue"
        />
        <label for="questionVal">{{ question.label }}</label>
      </div>
    </div>
  </form>
  <div class="buttons">
    <button class="btn btn-primary" (click)="trySubmitRejection()">
      {{'DATA_QUALITY.SUBMIT_REJECTION' | translate}}
    </button>
    <button
      class="btn btn-danger"
      style="margin-left: 1rem"
      (click)="cancel($event)"
    >
      {{'COMMON.CANCEL' | translate}}
    </button>
  </div>
</div>

import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { HttpInterceptorService } from './helpers/interceptor';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule, NgxUiLoaderConfig, POSITION, SPINNER } from 'ngx-ui-loader';
import { AppConfig } from './config/config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './layout/app.component';
import { PrivateLayoutComponent } from './layout/private-layout/private-layout.component';
import { PublicLayoutComponent } from './layout/public-layout/public-layout.component';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/header/header.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { FormsModule } from '@angular/forms';
import { MultiSelectDropdownComponent } from './components/common/multi-select-dropdown/multi-select-dropdown.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { CrudComponent } from './components/crud/crud.component';
import { CrudTsComponent } from './components/crud/crud-ts/crud-ts.component';
import { PagingComponent } from './components/paging/paging.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { AnalyticsOverviewComponent } from './components/dashboard/analytics-overview/analytics-overview.component';
import { OperationsAnalyticsComponent } from './components/dashboard/operations-analytics/operations-analytics.component';
import { SalesManagementComponent } from './components/dashboard/sales-management/sales-management.component';
import { NumberCompactPipe } from './pipes/number-compact.pipe';
import { NgChartsModule } from 'ng2-charts';
import { BarChartComponent } from './components/bar-chart/bar-chartcomponent';
import { DoughnutChartComponent } from './components/doughnut-chart/doughnut-chart.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { MapComponent } from './components/map/map.component';
import { AgmOverlays } from 'agm-overlays';
import { AgmCoreModule } from '@agm/core';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { UpdatePasswordComponent } from './user/update-password/update-password.component';
import { AddCustomerComponent } from './components/customers/add-customer/add-customer.component';
import { EditCustomerComponent } from './components/customers/edit-customer/edit-customer.component';
import { UploadCustomersComponent } from './components/customers/upload-customers/upload-customers.component';
import { ClickStopPropagation } from './directives/ClickStopPropagation';
import { CustomersLayoutComponent } from './components/customers/customers-layout/customers-layout.component';
import { CustomersComponent } from './components/customers/customers-list/customers.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { UpdateProfileComponent } from './user/update-profile/update-profile.component';
import { UsersLayoutComponent } from './user/users-layout/users-layout.component';
import { BuildingsLayoutComponent } from './components/buildings/buildings-layout/buildings-layout.component';
import { BuildingsComponent } from './components/buildings/buildings-list/buildings/buildings.component';
import { CardAnalyticsComponent } from './components/card-analytics/card-analytics.component';
import { EditBuildingsComponent } from './components/buildings/edit-buildings/edit-buildings/edit-buildings.component';
import { ProgressBarAngularModule } from 'progress-bar-angular';
import { CrudF33Component } from './components/crud/crud-f33/crud-f33.component';
import { CrudSsComponent } from './components/crud/crud-ss/crud-ss.component';
import { CrudF11Component } from './components/crud/crud-f11/crud-f11.component';
import { CrudDtComponent } from './components/crud/crud-dt/crud-dt.component';
import { CrudAreaComponent } from './components/crud/crud-area/crud-area.component';
import { CrudRegionComponent } from './components/crud/crud-region/crud-region.component';
import { CrudTariffComponent } from './components/crud/crud-tariff/crud-tariff.component';
import { CrudMeterTypeComponent } from './components/crud/crud-meter-type/crud-meter-type.component';
import { AddBuildingComponent } from './components/buildings/add-building/add-building.component';
import { UploadComponent } from './components/upload/upload.component';
import { PolesComponent } from './components/poles/poles-list/poles/poles.component';
import { PolesLayoutComponent } from './components/poles/poles-layout/poles-layout.component';
import { EditPolesComponent } from './components/poles/edit-poles/edit-poles.component';
import { AddPoleComponent } from './components/poles/add-pole/add-pole.component';
import { BuildingMapComponent } from './components/building-map/building-map.component';
import { TransformersLayoutComponent } from './components/transformers/transformers-layout/transformers-layout.component';
import { TransformersComponent } from './components/transformers/transformers-list/transformers.component';
import { AddTransformerComponent } from './components/transformers/add-transformer/add-transformer.component';
import { EditTransformerComponent } from './components/transformers/edit-transformer/edit-transformer.component';
import { AuditLogComponent } from './components/audit-log/audit-log.component';
import { MetersLayoutComponent } from './components/meters/meters-layout/meters-layout.component';
import { MetersListComponent } from './components/meters/meters-list/meters-list.component';
import { EditMeterComponent } from './components/meters/edit-meter/edit-meter.component';
import { AddMeterComponent } from './components/meters/add-meter/add-meter.component';
import { DashboardMultiSelectDropdownComponent } from './components/common/dashboard-multi-select-dropdown/dashboard-multi-select-dropdown.component';
import { CrudCustomerComponent } from './components/crud/crud-customer/crud-customer.component';
import { CrudAssetRatingComponent } from './components/crud/crud-asset-rating/crud-asset-rating.component';
import { CrudDtMeterTypeComponent } from './components/crud/crud-dt-meter-type/crud-dt-meter-type.component';
import { CrudAssetStatusComponent } from './components/crud/crud-asset-status/crud-asset-status.component';
import { HistoryComponent } from './components/history/history.component';
import { SurveyLayoutComponent } from './components/survey/survey-layout/survey-layout.component';
import { SurveyComponent } from './components/survey/survey/survey.component';
import { AddSurveyComponent } from './components/survey/add-survey/add-survey.component';
import { SurveyDetailsComponent } from './components/survey/survey-details/survey-details.component';
import { InstallationLayoutComponent } from './components/installations/installation-layout/installation-layout.component';
import { InstallationsListComponent } from './components/installations/installations-list/installations-list.component';
import { AddInstallationComponent } from './components/installations/add-installation/add-installation.component';
import { InstallationDetailsComponent } from './components/installations/installation-details/installation-details.component';
import { FilterChecklistComponent } from './components/common/filter-checklist/filter-checklist.component';
import { AuditReviewComponent } from './audit-review/audit-review.component';

import { EditOnboardCustomerComponent } from './components/customers/edit-onboard-customer/edit-onboard-customer.component';
import * as Sentry from '@sentry/angular-ivy';
import { RoleManagementComponent } from './role-management/role-management.component';
import { PermissionMenuComponent } from './role-management/permission-menu/permission-menu.component';
import { CreateRoleComponent } from './role-management/create-role/create-role.component';
import { BpsAdminDashboardComponent } from './components/bps_admin/bps-admin-dashboard/bps-admin-dashboard.component';
import { UtilityListComponent } from './components/bps_admin/utility-management/utility-list/utility-list.component';
import { UtilityLayoutComponent } from './components/bps_admin/utility-management/utility-layout/utility-layout.component';
import { AddUtilityComponent } from './components/bps_admin/utility-management/add-utility/add-utility.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { UtilityDetailsComponent } from './components/bps_admin/utility-management/utility-details/utility-details.component';
import { UtilityDropdownComponent } from './components/bps_admin/utility-dropdown/utility-dropdown.component';
import { UtilityAssetAndCustomerEntitiesComponent } from './components/bps_admin/utility-asset-and-customer-entities/utility-asset-and-customer-entities.component';
import { BpsAdminJobHistoryComponent } from './components/bps_admin/bps-admin-job-history/bps-admin-job-history.component';
import { HelpComponent } from './components/login/help/help.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { RegisterComponent } from './components/kyc/register/register.component';
import { LandingComponent } from './components/kyc/landing/landing.component';
import { LoginHeaderComponent } from './components/kyc/login-header/login-header.component';
import { PasswordStrengthComponent } from './components/kyc/password-strength/password-strength.component';
import { LoginKycComponent } from './components/kyc/login-kyc/login-kyc.component';
import { DashboardKycComponent } from './components/kyc/dashboard-kyc/dashboard-kyc.component';
import { ResetPasswordComponent } from './components/kyc/reset-password/reset-password.component';
import { SetNewPasswordComponent } from './components/kyc/set-new-password/set-new-password.component';
import { SharedModule } from './_modules/shared/shared.module';
import { ReportsComponent } from './components/reports/reports.component';
import { ReportCustomersComponent } from './components/reports/report-customers/report-customers.component';
import { WorkOrdersComponent } from './components/work-orders/work-orders.component';
import { SiteVerificationListComponent } from './components/work-orders/site-verification/site-verification-list/site-verification-list.component';
import { SiteVerificationDetailsComponent } from './components/work-orders/site-verification/site-verification-details/site-verification-details.component';
import { KycDetailsComponent } from './components/customers/kyc-details/kyc-details.component';
import { MaintenanceListComponent } from './components/work-orders/maintenance/maintenance-list/maintenance-list.component';
import { NewWorkOrderComponent } from './components/work-orders/maintenance/new-work-order/new-work-order.component';
import { WorkOrdersLayoutComponent } from './components/work-orders/work-orders-layout/work-orders-layout.component';
import { WorkOrderDetailsComponent } from './components/work-orders/maintenance/work-order-details/work-order-details.component';
import { HistoryTrackerComponent } from './components/history-tracker/history-tracker.component';
import { CrudSltComponent } from './components/crud/crud-slt/crud-slt/crud-slt.component';
import { CrudMeterPriceComponent } from './components/crud/crud-meter-price/crud-meter-price.component';
import { DataQualityRejectionComponent } from './components/reports/data-quality-rejection/data-quality-rejection.component';
import { CustomersTabComponent } from './components/reports/data-quality-rejection/customers-tab/customers-tab.component';
import { BuildingsTabComponent } from './components/reports/data-quality-rejection/buildings-tab/buildings-tab.component';
import { LtPolesTabComponent } from './components/reports/data-quality-rejection/poles-tab/poles-tab.component';
import { TransformersTabComponent } from './components/reports/data-quality-rejection/transformers-tab/transformers-tab.component';
// import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
// import { AngularFireAnalytics, AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AdminLevelPipe } from './pipes/admin-level.pipe';
import { MeterStockInventory } from './components/meterManagement/meter-stock-inventory/meter-stock-inventory.component';
import { NewServiceComponent } from './components/new-service/new-service.component';
import { NewServiceDetailsComponent } from './components/new-service/new-service-details/new-service-details.component';
import { NewServiceLayoutComponent } from './components/new-service/new-service-layout/new-service-layout.component';
import { ServiceConnectionListComponent } from './components/work-orders/service-connection/service-connection-list/service-connection-list.component';
import { MeterStockUtilizationComponent } from './components/meterManagement/meter-stock-utilization/meter-stock-utilization.component';
import { MeterStockDecommissionedListComponent } from './components/meterManagement/meter-stock-decommissioned/meter-stock-decommissioned-list/meter-stock-decommissioned-list.component';
import { OptionsTooltipComponent } from './components/common/options-tooltip/options-tooltip.component';
import { ServiceConnectionDetailsComponent } from './components/work-orders/service-connection/service-connection-details/service-connection-details.component';
import { MeterStockDecommissionedDetailsComponent } from './components/meterManagement/meter-stock-decommissioned/meter-stock-decommissioned-details/meter-stock-decommissioned-details.component';
import { MeterStockDecommissionedLayoutComponent } from './components/meterManagement/meter-stock-decommissioned/meter-stock-decommissioned-layout/meter-stock-decommissioned-layout.component';
import { StockItemValuePipe } from './pipes/stock-item-value.pipe';
import { RegularizationComponent } from './components/reports/regularization/regularization.component';
import { CrudBillingAccountComponent } from './components/crud/crud-billing-account/crud-billing-account.component';
import { AddBillingAccountComponent } from './components/customers/add-billing-account/add-billing-account.component';
import { DataQualityComponent } from './components/data-quality/data-quality.component';
import { AuditReviewCustomerComponent } from './audit-review/audit-review-customer/audit-review-customer.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ReadingsComponent } from './components/readings/readings.component';
import { ReadingsLayoutComponent } from './components/readings/readings-layout/readings-layout.component';
import { MeterReadingsListComponent } from './components/readings/meter-readings/meter-readings-list/meter-readings-list.component';
import { ReadingDetailsComponent } from './components/readings/meter-readings/reading-details/reading-details.component';
import { GetReadingStatusClassPipe } from './pipes/get-reading-status-class.pipe';
import { ReadingTextBoxComponent } from './components/readings/meter-readings/reading-details/reading-text-box/reading-text-box.component';
import { UtilityDropdownSingleComponent } from './components/bps_admin/utility-dropdown-single/utility-dropdown-single.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsPosition: POSITION.centerCenter,
  bgsColor: 'var(--primary-color)',
  fgsColor: 'var(--primary-color)',
  overlayColor: 'transparent',
  bgsOpacity: 0.85,
  bgsSize: 70,
  fgsSize: 70,
  fgsType: SPINNER.rectangleBounce,
  bgsType: SPINNER.rectangleBounce,
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    PrivateLayoutComponent,
    PublicLayoutComponent,
    LoginComponent,
    HeaderComponent,
    DashboardComponent,
    SidenavComponent,
    MultiSelectDropdownComponent,
    ForgotPasswordComponent,
    AnalyticsOverviewComponent,
    OperationsAnalyticsComponent,
    SalesManagementComponent,
    BarChartComponent,
    DoughnutChartComponent,
    LineChartComponent,
    MapComponent,
    UserManagementComponent,
    RoleManagementComponent,
    CrudComponent,
    CrudTsComponent,
    PagingComponent,
    UserProfileComponent,
    UpdatePasswordComponent,
    CustomersComponent,
    AddCustomerComponent,
    EditCustomerComponent,
    EditMeterComponent,
    UploadCustomersComponent,
    ClickStopPropagation,
    CustomersLayoutComponent,
    DragAndDropDirective,
    UpdateProfileComponent,
    UsersLayoutComponent,
    BuildingsLayoutComponent,
    BuildingsComponent,
    CardAnalyticsComponent,
    EditBuildingsComponent,
    CrudF33Component,
    CrudSsComponent,
    CrudF11Component,
    CrudDtComponent,
    CrudAreaComponent,
    CrudRegionComponent,
    CrudTariffComponent,
    CrudMeterTypeComponent,
    EditBuildingsComponent,
    AddBuildingComponent,
    UploadComponent,
    PolesComponent,
    PolesLayoutComponent,
    EditPolesComponent,
    AddPoleComponent,
    BuildingMapComponent,
    TransformersLayoutComponent,
    TransformersComponent,
    AddTransformerComponent,
    EditTransformerComponent,
    AuditLogComponent,
    MetersLayoutComponent,
    MetersListComponent,
    AddMeterComponent,
    DashboardMultiSelectDropdownComponent,
    CrudAssetRatingComponent,
    CrudCustomerComponent,
    CrudDtMeterTypeComponent,
    CrudAssetStatusComponent,
    HistoryComponent,
    SurveyLayoutComponent,
    SurveyComponent,
    AddSurveyComponent,
    SurveyDetailsComponent,
    InstallationLayoutComponent,
    InstallationsListComponent,
    AddInstallationComponent,
    PermissionMenuComponent,
    CreateRoleComponent,
    InstallationDetailsComponent,
    FilterChecklistComponent,
    DataQualityComponent,
    EditOnboardCustomerComponent,
    BpsAdminDashboardComponent,
    UtilityListComponent,
    UtilityLayoutComponent,
    AddUtilityComponent,
    UtilityDetailsComponent,
    UtilityDropdownComponent,
    UtilityAssetAndCustomerEntitiesComponent,
    FilterChecklistComponent,
    AuditReviewComponent,
    BpsAdminDashboardComponent,
    BpsAdminJobHistoryComponent,
    HelpComponent,
    RegisterComponent,
    LandingComponent,
    LoginHeaderComponent,
    PasswordStrengthComponent,
    LoginKycComponent,
    DashboardKycComponent,
    ResetPasswordComponent,
    SetNewPasswordComponent,
    ReportsComponent,
    ReportCustomersComponent,
    WorkOrdersComponent,
    SiteVerificationListComponent,
    SiteVerificationDetailsComponent,
    KycDetailsComponent,
    MaintenanceListComponent,
    NewWorkOrderComponent,
    WorkOrdersLayoutComponent,
    WorkOrderDetailsComponent,
    HistoryTrackerComponent,
    CrudSltComponent,
    CrudMeterPriceComponent,
    DataQualityRejectionComponent,
    CustomersTabComponent,
    BuildingsTabComponent,
    LtPolesTabComponent,
    TransformersTabComponent,
    AdminLevelPipe,
    MeterStockInventory,
    MeterStockUtilizationComponent,
    NewServiceComponent,
    NewServiceDetailsComponent,
    NewServiceLayoutComponent,
    ServiceConnectionListComponent,
    MeterStockUtilizationComponent,
    MeterStockDecommissionedListComponent,
    OptionsTooltipComponent,
    ServiceConnectionDetailsComponent,
    MeterStockDecommissionedDetailsComponent,
    MeterStockDecommissionedLayoutComponent,
    StockItemValuePipe,
    RegularizationComponent,
    CrudBillingAccountComponent,
    AddBillingAccountComponent,
    AuditReviewCustomerComponent,
    ReadingsComponent,
    ReadingsLayoutComponent,
    MeterReadingsListComponent,
    ReadingDetailsComponent,
    GetReadingStatusClassPipe,
    ReadingTextBoxComponent,
    UtilityDropdownSingleComponent
  ],
  imports: [
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule.forRoot({ showForeground: true }),
    NgxUiLoaderHttpModule.forRoot({exclude:[environment["pathApi"] +'customer/getStats'], showForeground: true}),
    NgChartsModule,
    AgmOverlays,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD3D0SdHzMW0BEUoDFRGWgFyrRK1fnd2LQ',
    }),
    ProgressBarAngularModule,
    ColorPickerModule,
    NgxImageZoomModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    })
    // AngularFireModule.initializeApp(environment.firebaseConfig),
    // AngularFireAnalyticsModule
  ],
  providers: [
    AppConfig,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // constructor(private analytics: AngularFireAnalytics) {
  //   if (environment.production) {
  //     this.analytics.setAnalyticsCollectionEnabled(true);
  //   } else {
  //     this.analytics.setAnalyticsCollectionEnabled(false);
  //   }
  // }
}

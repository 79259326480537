import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from 'src/app/config/config';

@Injectable({
    providedIn: 'root'
  })
  export class DashboardBpsService {
  
    private pathApi = this.config.setting['pathApi'] + "bps/dashboard/";
    private headers = new HttpHeaders({'Content-Type': 'application/json'});
    
    constructor(private http: HttpClient, private config: AppConfig) {
  
    }
    
    getAnalyticsOveriview(utilityId : any){
      return this.http.post<any>(`${this.pathApi}getAnalyticsOverview`, {utilityId}, {headers: this.headers, observe: 'response'});
    }
    
    //for graph
    getAssetsCounts(utilityId : number) {
      return this.http.post<any>(`${this.pathApi}getAssetsCounts`, {utilityId} ,{headers: this.headers, observe: 'response'});
    }
    getOperationsAnalytics(obj : any) {
      return this.http.post<any>(`${this.pathApi}getOperationsAnalytics`, obj, {headers: this.headers, observe: 'response'});
    }
    //for map
    getAnalyticsOveriviewBuilding(utilityId : number){
      return this.http.post<any>(`${this.pathApi}getAnalyticsOverviewBuilding`,{utilityId},{headers: this.headers, observe: 'response'});
    }
    getAnalyticsOveriviewCustomer(utilityId : number){
      return this.http.post<any>(`${this.pathApi}getAnalyticsOverviewCustomer`,{utilityId},{headers: this.headers, observe: 'response'});
    }
    getAnalyticsOveriviewDt(utilityId : number){
      return this.http.post<any>(`${this.pathApi}getAnalyticsOverviewDt`,{utilityId},{headers: this.headers, observe: 'response'});
    }
    getAnalyticsOveriviewPole(utilityId : number){
      return this.http.post<any>(`${this.pathApi}getAnalyticsOverviewPole`,{utilityId},{headers: this.headers, observe: 'response'});
    }
    getMapData(obj: any) {
      let param = JSON.stringify(obj);
      return this.http.post<any>(`${this.pathApi}getMapData`,param,{headers: this.headers, observe: 'response'});
    }
    getGraphData(request : any) {
      let param = JSON.stringify(request);
      return this.http.post<any>(`${this.pathApi}getGraphData`,param,{headers: this.headers, observe: 'response'});
    }
}
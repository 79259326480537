import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SetNewPassword } from 'src/app/models/setNewPassword.model';
import { UtilityBasicInfo } from 'src/app/models/utility.model';
import { AccountKycService } from 'src/app/services/kyc/account-kyc.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-set-new-password',
  templateUrl: './set-new-password.component.html',
  styleUrls: ['./set-new-password.component.scss']
})
export class SetNewPasswordComponent implements OnInit {
  token: string;
  email: string;
  showNewPw: boolean = false;
  showConfirmPw: boolean = false;
  pass: string = '';
  confirmPass: string = '';
  strongPassword = false;
  utility: UtilityBasicInfo = new UtilityBasicInfo();

  constructor(private route: ActivatedRoute, private toastr: ToastrService, 
              private accountKycService: AccountKycService, private router: Router, private utilityService: UtilityService) { }

  ngOnInit(): void {
    var utilityRoute = this.route.snapshot.paramMap.get('utility') || '';

    if (utilityRoute) {
      this.tryGetUtilityByName(utilityRoute);
      this.token = this.route.snapshot.queryParams['token'];
      this.email = this.route.snapshot.queryParams['email'];
    } else this.router.navigate(['']);
    
  }

  onPasswordStrengthChanged(event: boolean) {
    this.strongPassword = event;
  }

  showNewPassword() {
    this.showNewPw = !this.showNewPw;
  }

  showConfirmPassword() {
    this.showConfirmPw = !this.showConfirmPw;
  }

  saveNewPassword() {
    if(this.pass != this.confirmPass) {
      this.toastr.error('Passwords are not matching!');
      return;
    }

    var newPasswordRequest = new SetNewPassword();
    newPasswordRequest.password = this.pass;
    newPasswordRequest.token = this.token.replace(new RegExp(" ", "g"), "+"); // URL + = SPACE
    newPasswordRequest.email = this.email;

    this.accountKycService.setNewPassword(newPasswordRequest).subscribe(res => {
      if (res.status === 200 || res.status === 'OK') {
        this.toastr.success('Password changed successfully!');
        this.router.navigate(['/new-connection/' + this.utility.name + '/login']);
      } else {
        this.toastr.error(res.message);      
      }}, 
    error => {
      this.toastr.error('An error occurred.');
    });
  }

  tryGetUtilityByName(name: string) {
    this.utilityService.getByName(name).subscribe({
      next: (response) => {
        const responseData = response.body;
        console.log(responseData)
        if (responseData?.status === 200 || responseData?.status === 'OK' && responseData.data) {
          this.utility = responseData.data;
        } else {
          this.router.navigate(['']);
        }
      },
      error: (_) => {
        this.router.navigate(['']);
      },
    });
  }
}

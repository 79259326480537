<div class="container-fluid customers-root">
  <!-- View Details implemented: -->
  <div class="table-root">
    <div class="d-flex justify-content-start switch-toggle transparent">
      <a class="separated-toggle-item" [ngClass]="currentTab == 1 ? 'active' : ''" (click)="changeTab(1)">
        <div>Customers</div>
      </a>
      <a class="separated-toggle-item" [ngClass]="currentTab == 2 ? 'active' : ''" (click)="changeTab(2)">
        <div>Buildings</div>
      </a>
      <a class="separated-toggle-item" [ngClass]="currentTab == 3 ? 'active' : ''" (click)="changeTab(3)">
        <div>Transformers</div>
      </a>
      <a class="separated-toggle-item" [ngClass]="currentTab == 4 ? 'active' : ''" (click)="changeTab(4)">
        <div>LT Poles</div>
      </a>
    </div>
    <div class="table-root-child">
      <div class="table-action-wrapper d-flex align-items-center justify-content-between">
        <div class="search-input">
          <img src="../../assets/icons/search.svg" alt="Search icon" (click)="search()">
          <input type="text" placeholder="Search" [(ngModel)]="searchText" (keydown.enter)="search()"
            (ngModelChange)="this.searchTextUpdate.next($event)">
        </div>
        <div class="right filter">
          <div>
            <div class="d-flex">
              <div class="datepicker-container">
                <label for="dateFrom"> From: </label>
                <input
                  type="date"
                  id="dateFrom"
                  name="dateFrom"
                  [ngClass]="
                    dateFrom! > dateTo!
                      ? 'btn datepicker-icon-error'
                      : 'btn datepicker-icon'
                  "
                  [(ngModel)]="dateFrom"
                  (change)="dateFromChange()"
                />
              </div>
  
              <div class="datepicker-container">
                <label for="dateTo" class="right-label"> To: </label>
                <input
                  type="date"
                  id="dateTo"
                  name="dateTo"
                  [ngClass]="
                    dateFrom! > dateTo!
                      ? 'btn datepicker-icon-error'
                      : 'btn datepicker-icon'
                  "
                  [(ngModel)]="dateTo"
                  (change)="dateToChange()"
                />
              </div>
            </div>
          </div>
          <select name="status-select" (change)="selectStatus($event)" class="form-select" >
            <option value="" [selected]="!status" disabled>Status</option>
            <option *ngFor="let s of statusOptions" value="{{s.value}}">
                {{s.name}}
            </option>
          </select> 

          <app-utility-dropdown-single (selectUtility)="selectUtility($event)"></app-utility-dropdown-single>

          <button class="btn btn-icon" (click)="download()">
            <span>Download</span>
          </button>
        </div>
      </div>
      <div class="table">
        <div>
          <table class="table border-bottom">
            <thead class="table-light">
              <tr>
                <th><input type="checkbox" class="form-check-input" [checked]="isAllCheckBoxChecked()"
                    (change)="checkAllCheckBox($event)"></th>
                <th>Utility</th>
                <th>Type</th>
                <th>SLRN</th>
                <th>Last update</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let asset of assets; let i=index;">
                <td><input type="checkbox" class="form-check-input" value="{{asset.id}}"></td>
                <td>{{asset.utilityName | hasValue}} </td>
                <td>{{asset.type | hasValue}} </td>
                <td>{{asset.slrn | hasValue}}</td>
                <td>{{asset.lastUpdateTime | date}}</td>
                <td>
                  <div *ngIf="asset.status" class="building-status" [ngClass]="{'complete-building': asset.status.toString() == 'Complete', 
                                    'incomplete-building': asset.status.toString() == 'Incomplete', 
                                    'pending-building': asset.status.toString() == 'Pending',
                                    'rejected-building': asset.status.toString() == 'Rejected',
                                    'assigned-building': asset.status.toString() == 'Assigned'}">
                    {{asset.status.toString()}}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            <app-paging *ngIf="count > 0" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage"
              [useDirect]="true" (pageChange)="pageChange($event)"></app-paging>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
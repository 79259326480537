import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { AssetStatus } from 'src/app/models/customer.model';
import { AssetBpsService } from 'src/app/services/bps/asset.service';

@Component({
  selector: 'app-utility-asset-and-customer-entities',
  templateUrl: './utility-asset-and-customer-entities.component.html',
  styleUrls: ['./utility-asset-and-customer-entities.component.scss'],
})
export class UtilityAssetAndCustomerEntitiesComponent implements OnInit {
  count: number = 2;
  pageSize: number = 10;
  currentPage: number = 1;
  currentTab: number = 1;
  searchText: string = '';
  searchTextUpdate = new Subject<string>();
  assets: any = [];
  selectedUtility: number;
  dateFrom?: string;
  dateTo?: string;
  status?: number;
  statusOptions = [
    { name: 'Pending', value: AssetStatus.Pending },
    { name: 'Complete', value: AssetStatus.Complete },
    { name: 'Incomplete', value: AssetStatus.Incomplete },
    { name: 'Assigned', value: AssetStatus.Assigned },
    { name: 'Rejected', value: AssetStatus.Rejected },
  ];

  constructor(
    private assetBpsService: AssetBpsService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  search() {
    this.reloadTable(1);
  }

  selectUtility(event: any) {
    this.selectedUtility = event;
    this.reloadTable(1);
  }

  checkAllCheckBox(event: any) {}

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  isAllCheckBoxChecked() {
    return false;
  }

  reloadTable(page: any = null) {
    if (page) this.currentPage = page;

    let pageInfo = {
      pageSize: this.pageSize,
      page: this.currentPage,
    };
    let filterParams = {
      search: this.searchText,
      utilityId: this.selectedUtility,
      asset: this.currentTab,
      status: this.status,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo ? `${this.dateTo}T23:59:59` : null,
    };

    this.assetBpsService.getAll({ pageInfo, filterParams }).subscribe({
      next: (response) => {
        const responseData = response.body;
        console.log(response);
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.count = responseData.data.count;
          this.assets = responseData.data.data;
          console.log(this.assets);
          if (responseData.message != '') {
            this.toastr.warning(responseData.message);
          }
        } else if (response?.status == 204) {
          this.toastr.warning('No content');
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => this.toastr.error('Error occured'),
    });
  }

  changeTab(tab: number) {
    this.currentTab = tab;
    this.searchText = '';
    this.reloadTable(1);
  }

  download() {
    let pageInfo = {
      pageSize: 0,
      page: 0,
    };
    let filterParams = {
      search: this.searchText,
      utilityId: this.selectedUtility,
      asset: this.currentTab,
      status: this.status,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo ? `${this.dateTo}T23:59:59` : null,
    };
    this.assetBpsService.download({ pageInfo, filterParams }).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else if (data?.status === 'Pending' || data?.status === '600') {
          this.toastr.info(data?.message || '');
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }

  selectStatus(e: any) {
    if (e.target.value != '') {
      this.status = e.target.value;
    } else {
      this.status = undefined;
    }
    this.reloadTable(1);
  }

  datesValid() {
    if (!this.dateFrom || !this.dateTo) {
      return true;
    }
    if (this.dateFrom && this.dateTo) {
      const valid: boolean =
        new Date(this.dateFrom).getTime() <
        new Date(`${this.dateTo.toString()}T23:59:59`).getTime();
      return valid;
    }
    return false;
  }

  dateFromChange() {
    if (!this.dateFrom) {
      this.dateFrom = undefined;
    }
    if (this.datesValid()) {
      this.search();
    }
  }

  dateToChange() {
    if (!this.dateTo) {
      this.dateTo = undefined;
    }
    if (this.datesValid()) {
      this.search();
    }
  }
}

<main class="container-fluid page-content">
  <div class="page-filter">
    <div class="leftFilter">
      <p class="logged-user">Welcome, {{ loggedUser }}</p>
      <p>Check out latest activities</p>
    </div>
      <!-- filter -->
      <div class="rightFilter">
        <app-utility-dropdown-single (selectUtility)="selectUtility($event)"></app-utility-dropdown-single>
      </div>
    </div>

  <!-- count cards -->
  <div class="card-wrapper row">
    <h2 class="card-title mb-25">Analytics Overview</h2>
    <div class="col-sm-6 col-md-4 col-xxl-4 box" *ngFor="let d of cardsData">
      <div class="card">
        <div class="asset-regions-data">
          <div class="total-nums">
            <div class="num-wrapper">
              <h3>{{ d.value }}</h3>
              <h2 class="num">{{ d.key | number }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- graph -->
  <operations-analytics [counts]="assetsCounts" [lineGraphData]="lineGraphData" [customersData]="customersData"
    (getData)="getOperationsAnalytics($event)"></operations-analytics>

  <!-- map -->
  <sales-management *ngIf="selectedUtility != undefined" [polesData]="polesData" [buildingsData]="buildingsSalesManagementData"
    [customersData]="customersData" [transformersData]="transformersData" [increasement]="increasement"
    [mapData]="selectedAssets" [utilityId]="this.selectedUtility" [reloadMapTrigger]="reloadMapTrigger"></sales-management>

  <!-- tagged asset breakdown -->
  <div class="row">
    <div class="card-wrapper col-sm-12 col-md-6 col-xxl-6">
      <h2 class="card-title mb-25">Tagged asset breakdown </h2>
      <div class="card doughnuts">
        <div class="row">
          <div class="col-md-4 p-5">
            <app-doughnut-chart [data]="buildingsDoughnutData.items" [title]="'Buildings'"
              [colors]="buildingsColors"></app-doughnut-chart>
          </div>
          <div class="col-md-4 p-5">
            <app-doughnut-chart [data]="transformersData.items" [title]="'Transformers'"
              [colors]="dtColors"></app-doughnut-chart>
          </div>
          <div class="col-md-4 p-5">
            <app-doughnut-chart [data]="polesData.items" [title]="'LT Poles'"
              [colors]="poleColors"></app-doughnut-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="card-wrapper col-sm-12 col-md-6 col-xxl-6 ps-0">
      <div class="d-flex">
        <div>
          <h2 class="card-title mb-25">Tagged asset breakdown </h2>
        </div>
        <div class="ms-auto">
          <div class="filter-bar anti-zoom">
            <div class="periods">
              <span [ngClass]="graphType === 'Yearly' ? 'period-item active' : 'period-item'"
                (click)="changeGraphType('Yearly')">Yearly</span>
              <span [ngClass]="graphType === 'Monthly' ? 'period-item active' : 'period-item'"
                (click)="changeGraphType('Monthly')">Monthly</span>
              <span [ngClass]="graphType === 'Daily' ? 'period-item active' : 'period-item'"
                (click)="changeGraphType('Daily')">Daily</span>
            </div>

            <div class="date">
              <span class="arrow" (click)="increasePage(1)">
                <img height="15px" width="15px" src="../../../assets/icons/chevron-left.svg" alt="Left arrow"/>
              </span>
              <span class="value">{{graphData.labels[0]}} - {{graphData.labels[graphData.labels.length - 1]}}</span>
              <span class="arrow" (click)="increasePage(-1)">
                <img height="15px" width="15px" src="../../../assets/icons/chevron-right.svg" alt="Right arrow"/>
              </span>
            </div>
          </div>
        </div>
      </div>
      <bar-chart class="card bar" id="chart" [graphData]="graphData"></bar-chart>
    </div>
  </div>
</main>